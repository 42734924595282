<script setup lang="ts">
import { type TriggerForm, type IRecipient, Channel } from '@critical-dates'
import { useFuse } from '@vueuse/integrations/useFuse'

const props = withDefaults(
  defineProps<{
    formValue: TriggerForm | undefined
    disabled?: boolean
  }>(),
  {
    formValue: () => ({}) as TriggerForm,
    disabled: false,
  },
)

const divisionId = getCurrentDivisionId()

const { data, isFetching } = useApiGetUserByCompanyId(divisionId)
const users = computed(() => data.value ?? [])

const query = ref<string>('')
const onSearch = (search: string) => {
  query.value = search
}
const recipients = ref<(IRecipient & { mobilePhone?: string })[]>([])

const { results: _results } = useFuse(query, users, {
  matchAllWhenSearchEmpty: true,
  fuseOptions: {
    keys: ['name', 'email'],
    threshold: 0.3,
    includeMatches: true,
    findAllMatches: false,
    shouldSort: true,
  },
})
const options = computed<(IRecipient & { mobilePhone?: string })[]>(() =>
  _results.value.map((result) => {
    const { id: userId, name, email, mobilePhone } = result.item
    return { userId, name, email, mobilePhone }
  }),
)

const hasSMS = computed(() => {
  return props.formValue.alertTypes?.some((alertType) => {
    // return alertType.channel === Channel.EMAIL
  })
})

const auth = useAuth()

watchOnce(users, () => {
  const user = users.value.find((user) => user.id === auth.data.value?.id)
  if (recipients.value.length === 0 && user) {
    recipients.value.push({
      userId: user.id,
      name: user.name,
      email: user.email,
      mobilePhone: user.mobilePhone,
    })
  }
})
</script>

<template>
  <FormKit
    v-model="recipients"
    type="taglist"
    label="Send notifications to"
    format="option"
    :disabled="disabled"
    hide-selected
    hide-display-value
    :loading="isFetching"
    placeholder="Select users"
    :searchable="true"
    :internal-search="false"
    name="recipients"
    label-by="name"
    track-by="userId"
    :options="options"
    validation="required|length:1"
    @search="onSearch"
  >
    <template #listitem="{ option: user }">
      <CriticalDateUserTagList
        :user="user"
        :show-phone="hasSMS"
        :button-phone="false"
      />
    </template>

    <template #taggeditem="{ option: user }">
      <CriticalDateUserTagList :user="user" :show-phone="hasSMS" button-phone />
    </template>
  </FormKit>
</template>
